/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
div.sub1 {
  float: left;
  display: block;
}
div.sub1 > .item {
  float: left;
  margin: 0 20px;
}
div.sub1 > .item.init {
  margin-left: 0;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  display: block;
  padding: 19px 0 15px;
  border-bottom: 5px solid transparent;
  color: #2D3C5A;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.625;
  font-family: 'AsapSemiBold', helvetica, sans-serif;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus {
  color: #D02C7D;
}
div.sub1 > .item > .menu.path {
  border-bottom-color: #D02C7D;
}
div.sub2 {
  position: absolute;
  display: block;
  width: 350px;
  z-index: 200;
  margin-top: -7px;
  background-color: #fff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
}
div.sub2 > .item {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  margin: 0;
  transition: max-height 250ms ease, margin 700ms ease;
}
div.sub2 > .item > .menu {
  display: block;
  margin: 0 30px;
  padding: 10px 0 10px 15px;
  background: url(/images/icon-caret-right.svg) no-repeat left -5px center;
  background-size: 15px 15px;
  color: #2D3C5A;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus {
  color: #D02C7D;
}
div.sub1 > .item.exit div.sub2 {
  right: 0;
}
div.sub1 > .item:hover div.sub2 > .item {
  max-height: 150px;
  transition: max-height 700ms ease, margin 700ms ease;
}
div.sub1 > .item:hover div.sub2 > .item.init {
  margin-top: 20px;
}
div.sub1 > .item:hover div.sub2 > .item.exit {
  margin-bottom: 20px;
}
#home {
  width: 198px;
  height: 67px;
  margin-top: 19px;
  margin-bottom: 18px;
}
#head {
  margin-top: 88px;
}
.cb-layout1 #head {
  margin-top: 70px;
}
.cb-layout4 #head {
  margin-top: 66px;
}
.left {
  width: 57.35524257%;
}
.right {
  float: right;
  width: 31.76838811%;
}
.nav.nav--mobile {
  display: none;
}
.nav.nav--desktop {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 13px;
}
.navi-services {
  justify-content: flex-end;
  padding-right: 0;
}
.navi-services .meta {
  padding-left: 20px;
  background-size: 15px 15px;
  background-position: left center;
}
.navi-services .meta.service_spenden {
  background-image: url(/images/icon-heart-grey.svg);
}
.navi-services .meta.service_phoneBern {
  background-image: url(/images/icon-phone-grey.svg);
}
.navi-services .meta.service_phoneBiel {
  background-image: url(/images/icon-phone-grey.svg);
}
.navi-services .meta.service_emailDesktop {
  background-image: url(/images/icon-envelope-grey.svg);
}
#naviServicesDesktop {
  display: block;
  float: left;
}
.languageDropdown {
  width: 40px;
  height: 26px;
}
.languageDropdown .cb-toggle {
  background-image: url(/images/icon-globe-grey.svg);
  background-size: 15px 15px;
  background-position: left center;
}
.navi.languageNavi {
  top: 0;
  padding-left: 15px;
  background-image: url(/images/icon-globe-grey.svg);
  background-size: 15px 15px;
  background-position: left center;
}
.navi.languageNavi > .item > .menu {
  padding: 0 8px;
}
.section--header {
  margin-top: 116px;
}
.header-content {
  width: 34.19405321%;
  margin-left: 8.52895149%;
}
.cb-layout2 .header-content,
.cb-layout3 .header-content {
  width: 43.81846635%;
  max-width: 560px;
}
.bubble {
  float: left;
  margin-left: 0;
  transform: translateY(0);
}
.theme-link {
  height: 131px;
  padding: 23px 44.33497537% 26px 32px;
}
.theme-link h3.theme-title {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1;
}
.theme-link .fake-link {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
.cb-layout1 h1 {
  font-size: 70px;
  font-size: 7rem;
  line-height: 1;
}
h2 .cb-layout1 .main {
  font-size: 35px;
  font-size: 3.5rem;
}
.cb-layout1 .area.main {
  width: 100%;
}
.cb-layout1 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.main .foot {
  margin-right: 1.14678899%;
  margin-left: 1.14678899%;
}
.cb-layout1 .area.main .part {
  margin-right: 1.14678899%;
  margin-left: 1.14678899%;
  width: 97.70642202%;
}
.cb-layout1 .area.main .tiny {
  width: 31.03975535%;
}
.cb-layout1 .area.main > .slim {
  width: 33.33333333%;
}
.cb-layout1 .area.main > .slim .foot,
.cb-layout1 .area.main > .slim .part {
  margin-right: 3.44036697%;
  margin-left: 3.44036697%;
}
.cb-layout1 .area.main > .slim .part {
  width: 93.11926606%;
}
.cb-layout1 .area.main > .slim .tiny {
  width: 43.11926606%;
}
.container--base {
  margin-top: 50px;
}
.area.base {
  width: 68.23161189%;
  margin-right: -1.17370892%;
}
.container--news {
  background: url(/images/frau_allgemein.svg) no-repeat;
  background-size: 425px 425px;
  background-position: right bottom;
}
.farwest,
.fareast {
  width: 40.29733959%;
}
.fareast {
  margin-left: 2.34741784%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -664px;
  width: 1328px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */